/**
 * toggle class with checkbox
 * add to trigger:  .js-checkbox-collapse- class to bind function
 *                  data-target - to set item to hide
 *
 * */
$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('change', '.js-checkbox-collapse', function (event) {
        event.preventDefault();

        var collapseTarget = $(this).data('target');

        if ($(this).is(":checked")) {
            $(collapseTarget).collapse('show');
            return;
        }
        $(collapseTarget).collapse('hide');
    });
});
