/**
 * Created by mitz.lad on 31/08/2016.
 */

/*
*  Helper that scrolls to target element on click requires a loink with href or an element with the target in a
*  data-scroll-to-target data attribute.
*
* */

$.fn.scrollToTarget = function (options) {
    return this.each(function (options) {
        var $link = $(this),
            $target;

        if ($link.attr('href').length > 0) {
            $target = $($link.attr('href'));
        } else if ($link.attr('data-scroll-to-target').length > 0) {
            $target = $link.attr('data-scroll-to-target');
        } else {
            return this;
        }

        $('html, body').animate({scrollTop: $target.offset().top - 50}, 800);

        return this;
    });
};
