$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-address-field').on("change keyup paste click", function(){

        var compiledAdress = $('.js-address-field-0').val() + ', ' + $('.js-address-field-1').val() + ', ' + $('.js-address-field-2').val();

        $('.js-compiled-address').val(compiledAdress);
    })
});
