$(document).ready(function () {

    var jQuery = $ = _jQuery;

    $('.ui-switch').each(function() {

        var $switch = $(this);

        var options = $switch.data('uiSwitch');

        $switch.bootstrapSwitch();

        $switch.on('switchChange.bootstrapSwitch', function (e, state) {
            var field = $switch.attr('name');
            state = state ? 1 : 0;
            //using get by default for now, even though should be something like put/patch
            $.get(options.endpoint+'?'+field+'='+state);
        });

    });
});