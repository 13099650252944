/**
 * Created by mitz.lad on 17/08/2016.
 */
/*
* Plugin to load image and appended to element, the plugin accepts options, and a callback.
* The loadImage call is applied to the container the image will be loaded into.
*
* The options accepts:
*   src - required - path to image
*   id - sets the id
*   class - set any classes
*   alt - sets alt text
*
* Usage:
*
*   $('div#container').image({ src: 'path/to/image.jpg', id:'set-id', class:'set classes', alt: 'alt tag text' },function(){
*      alert('The image is loaded and appended to #container');
*   });
*
* **/
$.fn.loadImage = function (options, cb) {
    return this.each(function () {
        var image = new Image();

        image.src = options.src;

        if (typeof options.id !== 'undefined') {
            image.id = options.id;
        }

        if (typeof options.class !== 'undefined') {
            image.className = options.class;
        }

        if (typeof options.alt !== 'undefined') {
            image.alt = options.alt;
        } else {
            image.alt = '';
        }

        if (typeof options.style !== 'undefined') {
            image.style = options.style;
        }

        image.onload = cb;

        this.appendChild(image);
    });
};
