/**
 * Created by Bobby on 13/10/2016.
 */

/*
 *    *   Smart search powered by Select2
 *
 *
 * */
$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;


    $('.js-select2').each(function () {

        var placeholder = $(this).data('placeholder');

        $(this).select2({
            placeholder: "Select a state",
            allowClear: true
        })

    });
});
