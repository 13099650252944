/**
 * Return boolean if passed argument is an array
 * */
(function($)
{
    $.isArray = function(obj) {
        if( Object.prototype.toString.call(obj) === '[object Array]' ) {
            return true;
        }

        return false;
    };
})(jQuery);