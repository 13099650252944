/**
 * toggle class with checkbox
 * add to trigger:  .js-checkbox-collapse- class to bind function
 *                  data-target - to set item to hide
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('change', '.js-radio-collapse', function (event) {
        event.preventDefault();

        var radioGroup = "input[name='"+ $(this).attr('name') + "']",
            collapseTarget = $(this).data('target');

        var targets = [];

        // Run through and hide all that are needed
        $(radioGroup).each(function(){
            if (!$(this).is(':checked')) {
                targets[$(this).data('target')] = "hide";
            }
        });

        // Go through again and show what should be show, splitting out allows multiple radios to be linked to same target
        $(radioGroup).each(function(){
            if ($(this).is(':checked')) {
                targets[$(this).data('target')] = "show";
            }
        });

        for (var target in targets)
        {
            $(target).collapse(targets[target]);
        }
    });
});
