/**
 * Created by mitz.lad on 17/11/2016.
 */

// get content of an element ignoring passed element
/*
*   eg.
*
* <div id="el"><b>Yo</b><span> Universe</span> here I am</div>
*
*
* var text = $('#el').ignore("span").text(); // "Hello here I am"
* var html = $('#el').ignore("span").html(); // "<b>Hello</b> here I am"
*
* */


(function($){
    $.fn.ignore = function(sel){
        return this.clone().find(sel||">*").remove().end();
    };
})(jQuery);
