/**
 * Created by mitz.lad on 18/11/2016.
 */
/*
*
* return elements that can have focus
* */

jQuery.extend(jQuery.expr[':'], {
    focusable: function (el, index, selector) {
        return $(el).is('a, button, :input:not(:hidden), [tabindex], select, textarea, .slider-handle, .listings__filter-form__more');
    }
});