$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    //Check to see if the top 100px(editable) of the document top is visible if not then display button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#js-back-to-top').fadeIn();
        } else {
            $('#js-back-to-top').fadeOut();
        }
    });

    //Click event to scroll to top
    $('#js-back-to-top').on('click', function () {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
    });

});
