/**
 * Created by mitz.lad on 18/08/2016.
 *
 * Initiate any tabbed contend by preventing tab links from causing page jumps.
 * Some tabs are conditionally visible so we won't always know which is the first tab, so add active states to the first tab and tab link that is rendered.
 *
 */
$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    var init = function ($tabs) {

        $('.js-nav-tabs [role="presentation"]', $tabs).first().addClass('active');
        $('.js-tab-content [role="tabpanel"]', $tabs).first().addClass('in active');

        $('.js-nav-tabs a', $tabs).on('click', function (event) {
            event.preventDefault();
            $(this).tab('show');
        });



    };

    var slideToTab = function () {
        $(document).ready(function () {
            $(document).on('click', '.js-slide-to-tab', function () {
                var $this = $(this);

                $this.scrollToTarget();
                $('[data-target="' + $this.data('tab') + '"]').tab('show');
            });
        });
    };


    $('.js-tabbed-panel').each(function () {
        init($(this));
    });

    // Javascript to enable link to tab
    var url = document.location.toString();
    if (url.match('#')) {
        var $bsTab = $('.js-nav-tabs a[href="#' + url.split('#')[1] + '"]');

        if ($bsTab.length > 0) {
            $bsTab.tab('show');
            $('html, body').animate({scrollTop: $bsTab.offset().top}, 800);
        }
    }

    slideToTab();

});