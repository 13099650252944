
/*
 *   Trigger youtube play from link click
 *
 *
 * */
$(document).ready(function() {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-play-video').on('click', function(ev) {

        var thisVideoLink = $(this),
            videoContainer = $(thisVideoLink).next('.js-video-container'),
            theVideo = $(videoContainer).find('.js-video');

        // $(".js-video")[0].src += "&autoplay=1";
        // $(".js-video-container").fadeIn();

        $(theVideo)[0].src += "&autoplay=1";
        $(videoContainer).fadeIn();
        ev.preventDefault();
    });
});
