/**
 * Created by mitz.lad on 23/08/2016.
 */

/*
 *   Selects which action and set of default fields are required for the selected search
 *
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-hoverdir').hoverdir({
        hoverElem: '.js-hoverdir-overlay'
    });

    $('.js-hoverdir').hover(function(){
       $('.js-hoverdir-title', this).slideToggle('down');
    });
});
