/**
 * Created by mitz.lad on 28/07/2016.
 */
$(document).ready( function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('click', '[data-toggle=collapse]', function (event) {
        event.preventDefault();
        $(this).blur();
    });
});
