/**
 * Created by mitz.lad on 23/08/2016.
 */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    jQuery = $ = _jQuery;

    $('.js-search-location').each(function () {

        var $searchLocation = $(this),
            $dropdownAction = $('.js-dropdown-action', $searchLocation),
            $selectedData = $dropdownAction.data('selectedSearch'),
            $selectedAction = $('[data-defaults="' + $selectedData + '"]').data('action');

        var toggleRequiredFields = function () {
            $('.js-form-required', $searchLocation).removeClass('js-form-required');
            $('[data-' + $selectedData + ']', $searchLocation).addClass('js-form-required');
            $searchLocation.attr('action', $selectedAction);
        };

        // set the action based on the default selected option
        toggleRequiredFields();

        $('[data-search-category]', $searchLocation).on('click', function (event) {
            event.preventDefault();
            var $searchCategory = $(this);
            $dropdownAction.text($searchCategory.text());
            $selectedData = $searchCategory.data('defaults');
            $selectedAction = $searchCategory.data('action');
            toggleRequiredFields();
        });

        //on submit remove hidden fields with default that are not required and then submit
        $searchLocation.on('submit', function (event) {
            event.preventDefault();

            $('input[type="hidden"]:not(.js-form-required)').remove();

            $searchLocation.unbind('submit').submit();
        });
    });
});
