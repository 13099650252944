/**
 * Created by mitz.lad on 21/07/2016.
 */
/**
 * Creates new slider control
 * .range(form-group-wrapper, units) - pass wrapper for control, markup must have a min and max value inputs to bind to model use class js-slider-min-val and js-slider-max-val to target
 *                              the inputs. Additionally add units as string to show on tool tip.
 *
 *   HTML:
 *
 *      <div id="js-slider-XXX" class="form-group">
 *          <label>XXX</label>
 *          <input id="filter-XXX-min" class="js-slider-min-val form-control" placeholder="min distance" name="" type="text">
 *          <input id="filter-XXX-max" class="js-slider-max-val form-control" placeholder="max distance" name="" type="text">
 *          <input id="js-XXX-input" class="js-slider-input" value="" data-slider-min="1" data-slider-max="500" data-slider-step="5" data-slider-value="[150,350]" type="text"/>
 *      </div>
 *
 *      TODO: on load tooltip uses default format instead of custom formatter, corrects after first click - attempted firing slide, sideStart, slideStop and Change events but didn't work
 * */



$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-ui-slider').each(function () {

        var $control = $(this),
            $input = $('.js-slider-input', $control),
            $lowerVal = $('.js-slider-min-val', $control),
            $upperVal = $('.js-slider-max-val', $control),
            uiSlider = $input.slider(),
            defaultMax = 1000; // a max value to exceed what is defined in the filters so the results include all values above the stated max


        $('.js-hide-control', $control).hide();

        uiSlider.slider({
            range: true,
            min: $input.data('sliderMin') ? $input.data('sliderMin') : 0,
            max: $input.data('sliderMax') ? $input.data('sliderMax') : defaultMax,
            step: $input.data('sliderStep') ? $input.data('sliderStep') : 1,
            value: $input.data('sliderValue') ? $input.data('sliderValue') : defaultMax,
            ticks: $input.data('sliderTicks') ? $input.data('sliderTicks') : undefined,
            formatter: function () {
                var values = $input.val().split(',');
                if (parseFloat(values[1]) == parseFloat($input.data('sliderMax')))
                    values[1] = values[1] + "+ ";
                return values[0] + ' - ' + values[1] + " " + $input.data('sliderUnits');
            }
        })
            .on('change', function () {
                var values = $input.val().split(',');

                var min_value = parseInt(values[0]);
                var max_value = parseInt(values[1]);
                $lowerVal.val(min_value);
                $upperVal.val(max_value);

                // if only 1 is selected, make sure it works
                if (min_value == max_value) max_value += 5;

                $control.find(".js-slider-checkbox").each(function () {
                    var checkbox_min = parseInt($(this).data("min"));

                    $(this).prop('checked', (checkbox_min >= min_value &&  checkbox_min < max_value));
                });

                $control.find(".js-slider-checkbox:last-of-type").prop('checked',(max_value == parseInt($input.data('sliderMax'))));
            })
            .on('slideStop', function (event) {
                if (event.value[0] <= parseFloat($input.data('sliderMin'))) {
                    $lowerVal.val(null);
                }
                if (event.value[1] >= parseFloat($input.data('sliderMax'))) {
                    $upperVal.val(null);
                }
            });

        // this is an attempt to stop the popin of the element, but dont think its needed
        //$(uiSlider.slider("getElement")).animate({opacity: 1}, 250);

        // force a tooltip refresh so correct value is shown
        try {
            var val = uiSlider.val();
            var min = parseInt(val.split(",")[0]);
            var max = parseInt(val.split(",")[1]);
            uiSlider.slider('setValue', [min, max]).trigger('change');
        }
        catch (e) {
            console.log(e);
        }

        if ($input.data('sliderLabels')) {
            var sliderTicks = $('.slider-tick', $control);

            for (var i = 0; i < sliderTicks.length; i++) {
                var $tick = $(sliderTicks[i]),
                    $label = $('<span/>'),
                    labelText = $input.data('sliderLabels')[i];

                $label.addClass('js-tick-label').text(labelText).appendTo($tick);
            }
        }
    });

    /**
     * UI slider for average ride distance, selects 2 checkboxes based on slider selection
     */
    $('.js-ui-distance-slider').each(function () {

        var $control = $(this),
        $input = $('.js-slider-input', $control),
            uiSlider = $input.slider(),
            /*
            Labels array added empty entries to handle odd values
             */
            labels = ["", "0-10", "", "10-20", "", "20-30", "", "30-40", "", "40-50", "", "50+"];

        $('.js-hide-control', $control).hide();

        uiSlider.slider({
            range: false,
            formatter: function () {
                var values = $input.val().split(',');
                if (parseFloat(values[1]) == parseFloat($input.data('sliderMax')))
                    values[1] = values[1] + "+ ";
                return labels[values[0]] + ' ' + $input.data('sliderUnits');
            }
        })
            .on('change', function () {
                /*
                Select 2 checkboxes based on the slider selection
                 */
                var secondCheckbox = parseInt($input.val()) + 1;
                //Unset checkboxes
                $('input[name="ride_distances[]"]:checked').prop('checked',false);
                //Check 2 selected values set in slider
                $('.js-ride_distance-'+$input.val()).prop('checked',true);
                $('.js-ride_distance-'+secondCheckbox).prop('checked',true);
        })

        uiSlider.slider('setValue', $input.data('slider-value'));
    });


});