/**
 * Created by adam.clarke on 26/05/2016.
 *
 * Used to trigger a set of actions on a link with various options to save having to write this muiltple times
 * for different things
 *
 * Call with the following to register custom events in data-action:
 *
 *
 *    $.fn.ajaxlink("event", {
       name: 'custom-event-name',
       pre: function (el) {

       },
       post: function (el) {

       }
   });

 *
 * will ajax load the data-url link or href link and run an action when done
 *
 * if data-confirm is present, a confirm popup will be shown that gets the ajax
 * on the ok button being clicked
 *
 * data-action = "remove" will remove the element in data-target
 * data-action = "refresh" will reload the page after the call
 */


(function ( $ ) {

    var events = {};

    $.fn.ajaxlink = function(options, params) {

        // sort out registering of custom events
        if (typeof(options) == "string")
        {
            if (options == "event")
            {
                events[params.name] = params;
            }

            return;
        }

        // add click event to all elements
        $(this).click( function (ev) {

            // stop base link event
            ev.preventDefault();

            // setup data
            var t = $(this);
            var url = $(this).data('url');
            var confirm = $(t).data('confirm');
            var confirmmessage = $(t).data('confirm-message');

            // if we have no data-url passed, use href instead
            if (typeof url == "undefined")
                url = $(this).attr('href');

            // called when we have done the ajax call
            function postAction()
            {
                var action = t.data('action');

                if (action == "refresh")
                {
                    location.reload();
                } else if (action == "remove") {

                } else if (typeof(events[action]) != "undefined")
                {
                    if (typeof(events[action].post) != "undefined") events[action].post(t);
                } else {
                    //alert("Unknown action on js-ajaxlink : " + action);
                }
            }

            // called before we do the call
            function preAction() {
                var action = t.data('action');

                if (action == "remove")
                {
                    $(t.data('target')).slideUp(200, this.remove);
                } else if (typeof(events[action]) != "undefined")
                {
                    if (typeof(events[action].pre) != "undefined") events[action].pre(t);
                }
            }

            // make the ajax call
            function makeCall()
            {
                preAction();

                $.ajax(url).done(function() {
                    postAction();
                });
            }

            // if we have a confirm message set, then trigger the confirm popup first
            // before running the call
            if (typeof (confirm) != "undefined")
            {
                popup.confirm(confirm, confirmmessage,
                    {
                        ok: 'Yes',
                        cancel: 'No',
                        okclass: 'btn-danger',
                        cancelclass: 'btn-success',
                        onok: function () {
                            makeCall();
                        }
                    });
            } else {
                // no confirm box, just run the call
                makeCall();
            }
        })
    };

    // set this up for all tagged elements
    $(document).ready(function () {
        $('.js-ajaxlink').ajaxlink();
    });

}( jQuery ));
