// Move element including all data, events and all child elements data and their events, defaults to bottom if position is undefined
// eg.
// $(".somDiv").moveTo($(".destination", "top");
(function($)
{
    $.fn.moveTo = function (targetElement, position) {

        var element = this.detach();

        switch (position) {
        case 'top':
            element.prependTo(targetElement);
            break;
        case 'before':
            element.insertBefore(targetElement);
            break;
        case 'after':
            element.insertAfter(targetElement);
            break;
        default: // move to bottom of targetElement
            element.appendTo(targetElement);
            break;
        }

        return this;
    };
})(jQuery);