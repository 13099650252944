/**
 * Created by adam.clarke on 14/11/2016.
 */
$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-scroll-to-next').click(function (ev) {
        ev.preventDefault();

        var row = $(this).closest(".block_container");

        $("html, body").animate({ scrollTop: row.offset().top + row.outerHeight() }, 500);
    });
});