/**
 * Created by adam.clarke on 10/11/2016.
 */
$(document).ready(function () {

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-onbike').each(function () {
        $this = $(this);
        $this.find('.js-onbike-type').change(function () {
            if ($(this).val() == "alone")
            {
                $this.attr("action", $this.data("url-ride"));
                $this.find(".js-onbike-ride").removeClass("hide");
                $this.find(".js-onbike-route").addClass("hide");

                $this.find('.js-onbike-distance-min').attr("name", "");
                $this.find('.js-onbike-distance-max').attr("name", "");

                $this.find(".js-onbike-ride").attr("name", "ride_level[]");
            } else {
                $this.attr("action", $this.data("url-route"));
                $this.find(".js-onbike-ride").addClass("hide");
                $this.find(".js-onbike-route").removeClass("hide");

                $this.find('.js-onbike-distance-min').attr("name", "distance_min");
                $this.find('.js-onbike-distance-max').attr("name", "distance_max");

                $this.find(".js-onbike-ride").attr("name", "");
            }
        });

        $this.find(".js-onbike-route select").change(function () {
            var val = $(this).val();

            var min = val.split(",")[0];
            var max = val.split(",")[1];

            $this.find(".js-onbike-distance-min").val(min);
            $this.find(".js-onbike-distance-max").val(max);
        })
    });

});