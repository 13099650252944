/**
 * Created by mitz.lad on 09/08/2016.
 */
/**
 * Generic module to hide an element
 * add to trigger:  .js-hide-trigger - class to bind function
 *                  data-hide-target - to set item to hide
 *                  data-hide-effect - to set jquery effect to use to hide
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('click', '.js-hide-trigger', function (event) {
        event.preventDefault();

        var effect  = $(this).data('hide-effect'),
            $target = $($(this).data('hide-target'));

        $target[effect]();
    });
});