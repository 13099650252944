/**
 * Created by adam.clarke on 13/11/2016.
 */

// save jquery object for later use so require js doesnt ruin it
var _jQuery = jQuery;

$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    // Remove no-js class and replace with js for css - remove if including modernizr
    $('.no-js').addClass('js').removeClass('no-js');

    // bind all scroll to target links
    $(document).on('click', '.js-scroll-to-target', function (event) {
        event.preventDefault();
        $(this).scrollToTarget();
    });

    /*setup listener for popstate and navigate to the last entry - ajaxRequest has method called by ajax calls
    to push the ajax call to the browser history*/
    window.addEventListener('popstate', function(event) {
        if (event.state) {
            window.location.href = location.href;
        }
    }, false);

    // navigate to any links that we don't know the domain for - currently used for links created as part of alerts we add the path in data-url
    // eg. <a href="" data-url-create data-path="/users/account/signup">sign up</a>
    $(document).on('click', '[data-url-create]', function (event) {
        event.preventDefault();

        // generate url to navigate to
        var url = window.location.protocol + '//' + window.location.host + $(this).data('path');

        // navigate to the generated path
        window.location.href = url;
    });

    // Simple text switch helper
    $(document).on('click', '.js-text-switch', function (event) {
        var hideClass = 'h-hide';
        var $visible = $(this).find('[data-visible]'),
            $invisible = $(this).find('[data-invisible]');

        $visible.each(function(){
            if ($(this).hasClass(hideClass)) {
                $(this).removeClass(hideClass);
            } else {
                $(this).addClass(hideClass);
            }
        });

        $invisible.each(function(){
            if ($(this).hasClass(hideClass)) {
                $(this).removeClass(hideClass);
            } else {
                $(this).addClass(hideClass);
            }
        });
    });

    // intialise clockpickers
    $('.clockpicker').each(function () {

        var $clockPicker = $(this);

        var data = $clockPicker.data('clockpickerConfig');

        var config = {
            //default
            autoclose  : true,
            twelvehour : false,
            afterDone: function() {
                var time = $('input', $clockPicker);
                time.parsley().validate();
            }
        };

        $.extend(config, data);

        $clockPicker.clockpicker(config);
    });
});
