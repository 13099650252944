/**
 * Created by mitz.lad on 11/08/2016.
 */
/**
 * Generic module to show an element
 * add to trigger:  .js-show-trigger - class to bind function
 *                  data-show-target - to set item to show
 *                  data-show-effect - to set jquery effect to use to show
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('click', '.js-show-trigger', function (event) {
        event.preventDefault();

        var effect  = $(this).data('show-effect'),
            $target = $($(this).data('show-target'));

        $target[effect]();
    });
});
