/**
 * Created by Bobby on 19/09/2016.
 */

/*
 * open and slide to tab passed in url hash value
 *
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    var hash = document.location.hash;

    if (hash) {
        var $scrollTo = $(hash);

        if ($scrollTo.length > 0) {
            $scrollTo.tab('show');

            if ($scrollTo.hasClass('js-collapse--on-load')) {
                $($scrollTo.data('collapseTarget')).collapse('show');
            }

            $("html, body").animate({scrollTop: $scrollTo.offset().top}, 500);
        }
    }
});
