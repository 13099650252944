/**
 * Created by mitz.lad on 21/11/2016.
 */

/*
*  Add any generic behaviour scripts here eg. when user interacts with tabs or collapse panels
* */

$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $('.js-copy-field-val').each(function () {
        var $this = $(this);

        $(document).on($this.data('copyTrigger'), $this, function () {
            var $elemFrom = $($this.data('copyFrom')),
                $elemTo = $($this.data('copyTo'));

            if ($elemFrom.length > 0 && $elemTo.length > 0) {
                $elemTo.val($elemFrom.val());

                $elemTo.attr('data-val-origin', '#' + $elemFrom.attr('id'));

                $elemTo.on('change', function () {
                    var $to = $(this);
                    $($to.data('valOrigin')).val($to.val());
                });
            }

            return true; // return true just in case the element is bound to other scripts
        });
    });
});