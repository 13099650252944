/**
 * Generic module to slide to an element
 * add to trigger:  .js-slide-trigger - class to bind function
 *                  data-slide-target - to set item to slide to (include # or . in attribute)
 *                  data-slide-offset - offset from top to ensure element is in view
 *
 * */
$(document).ready(function () {
    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    $(document).on('click.slideTo', '.js-slide-trigger', function (event) {
        event.preventDefault();
        var $this = $(this);

        var doScroll = function () {
            'use strict';

            var offset = $this[0].hasAttribute('data-slide-offset')? $this.data('slideOffset') : 0;
            var scrollTo = $($this.data('slideTarget')).offset().top - offset;

            $('html, body').animate({scrollTop: scrollTo}, 800);
            return false;
        };

        // if this is going to a collapse panel wait until the collapse has been show before the scroll
        if ($this[0].hasAttribute('data-toggle') && $this.data('toggle') === 'collapse') {
            $($this.data('target')).on('shown.bs.collapse', function () {
                doScroll();
            });

            return;
        } else {
            doScroll();
        }

        return;
    });
});
