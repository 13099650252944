/**
 * returns an array of all data params on an element that have a specific prefix
 */
(function($)
{
    $.fn.dataParams = function (scope) {
        var d = {};

        $.each($(this).data(), function (index, attr) {
            if (index.match("^" + scope)) {
                d[index.replace(scope, "").toLowerCase()] = attr;
            }
        });

        return d;
    };
})(jQuery);