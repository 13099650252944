/**
 * Created by mitz.lad on 22/07/2016.
 */
/**
 * Date range picker
 *
 * Populates From and To date fields when a date range is selected. Fields hidden when range is called.
 *
 * Docs & Demo: https://github.com/dangrossman/bootstrap-daterangepicker
 */

$(document).ready(function () {
    var dateFormat = 'DD/MM/YYYY';

    // pull in global jquery instance that has all the plugins loaded
    var jQuery = $ = _jQuery;

    var range = function ($control) {

        var $dateRangePickerInput = $('.js-daterangepicker-input', $control);
        var startDate = $dateRangePickerInput.attr('data-range-from');
        var endDate = $dateRangePickerInput.attr('data-range-to');

        if (startDate == "") {
            startDate = moment.utc();
        } else {
            startDate = moment.utc(startDate, dateFormat);
        }

        if (endDate == "") {
            endDate = moment.utc().add(3, 'months');
        } else {
            endDate = moment.utc(endDate, dateFormat);
        }

        $('.js-hide-control', $control).hide();

        var options = {
            startDate: startDate,
            endDate: endDate,
            locale: {
                cancelLabel: 'Clear'
            },
            showDropdowns: true,
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            maxDate: moment.utc().add(1, 'years'),
        };

        if (!$dateRangePickerInput.data("no-minimum")) {
            options.minDate = moment.utc();
        } else {
            options.minDate = '01/01/2010';
        }

        if ($dateRangePickerInput.data("use-ranges")) {
            options.ranges = {
                'Last Week': [moment().subtract(7, 'days').startOf('week').add(1, 'days'), moment().subtract(7, 'days').endOf('week').add(1, 'days')],
                'This Week': [moment().startOf('week').add(1, 'days'), moment().endOf('week').add(1, 'days')],
                'Next Week': [moment().add(7, 'days').startOf('week').add(1, 'days'), moment().add(7, 'days').endOf('week').add(1, 'days')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
            }
        }

        $dateRangePickerInput.daterangepicker(options);

        if (startDate != "" && endDate != "") {
            $('.js-value', $dateRangePickerInput).html(moment.utc(startDate, dateFormat).format(dateFormat) + ' - ' + moment.utc(endDate, dateFormat).format(dateFormat));
        }

        $dateRangePickerInput.on('apply.daterangepicker', function (ev, picker) {
            $('.js-value', this).html(picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat));
            $('[name="date_from"]', $control).val(picker.startDate.format(dateFormat));
            $('[name="date_to"]', $control).val(picker.endDate.format(dateFormat));
        });

        $dateRangePickerInput.on('cancel.daterangepicker', function () {
            $('.js-value', this).html('');
            $('[name="date_from"]', $control).val('');
            $('[name="date_to"]', $control).val('');
        });
    };

    var single = function ($control) {
        var $dateRangePickerInput = $('.js-daterangepicker-input', $control);

        $('.js-hide-control', $control).hide();

        $dateRangePickerInput.daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            minDate: moment.utc()
        });

        $dateRangePickerInput.on('apply.daterangepicker', function (ev, picker) {
            $('.js-value', this).html(picker.startDate.format(dateFormat));
            $('.js-single-date', $control).val(picker.startDate.format(dateFormat)).trigger('change');

            // copy value to any dependant fields - input tag must have data attributes to reference the display span and input field
            if ($dateRangePickerInput.hasClass('js-date-copy-val')) {
                var $inputField = $($dateRangePickerInput.data('copyInputField')),
                    $displayField = $($dateRangePickerInput.data('copyDisplayField'));

                $inputField.val(picker.startDate.format(dateFormat)).trigger('change');
                $displayField.html(picker.startDate.format(dateFormat));

                //updating the actual daterangepicker view as well
                var dateRangePicker = $displayField.parent('.js-daterangepicker-input').data('daterangepicker');
                if (dateRangePicker.constructor.name === 'DateRangePicker'){
                    var d = picker.startDate.clone();
                    dateRangePicker.setStartDate(d);
                    dateRangePicker.setEndDate(dateRangePicker.startDate.clone());
                }
            }
        });

    };

    $('.js-datepicker-single').each(function () {
        single($(this));
    });

    $('.js-datepicker-range').each(function () {
        range($(this));
    });
});